var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "driv-checkbox" }, [
    _c(
      "label",
      { class: ["checkbox-label", { disabled: _vm.disabled }] },
      [
        _c("input", {
          staticClass: "checkbox-label_input",
          attrs: { type: "checkbox", disabled: _vm.disabled },
          domProps: { checked: _vm.checked },
          on: { change: _vm.onChange }
        }),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }