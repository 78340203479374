var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "driv-tabs-list__links" },
    _vm._l(_vm.tabs, function(tab) {
      return _c(
        "li",
        {
          key: tab.value,
          class: [
            "tab-link",
            { "tab-link_selected": _vm.currentTabValue === tab.value }
          ],
          on: {
            click: function($event) {
              return _vm.$emit("select", tab.value)
            }
          }
        },
        [
          _vm._v(
            "\n        " + _vm._s(_vm.getTranslation(tab.label)) + "\n    "
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }