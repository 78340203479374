var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ag-grid-vue", {
    staticClass: "ag-theme-alpine",
    staticStyle: { width: "100%" },
    attrs: {
      domLayout: "autoHeight",
      columnDefs: _vm.columnConfig,
      rowData: _vm.tableData,
      gridOptions: _vm.gridOptions
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }