<template>
    <div :class="['driv-section', { 'show-content': isOpened }]">
        <button class="driv-section__title" @click="toggleContent">
            {{ getTranslation(title) }}
        </button>
        <span class="driv-section__label" v-show="title === 'Part Types / Positions' && isWalker">
            ( {{ getTranslation('Select Part type to see positions') }} )
        </span>
        <div class="driv-section__content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default Vue.component("driv-section", {
    data() {
        return {
            isOpened: false,
            isWalker: false,
        };
    },
    props: {
        title: {
            type: String,
            default: "",
        },
        isInitiallyOpened: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        toggleContent() {
            this.isOpened = !this.isOpened;
        },
        getTranslation(text) {
            return Vue.i18n.get(text);
        },
        checkBrandWalker() {
            const bodyTag = document.body.getAttribute('ng-app');

            return bodyTag === 'fmmp.walker';
        },
    },
    created() {
        this.isOpened = this.isInitiallyOpened;
        this.isWalker = this.checkBrandWalker();
    },
});
</script>
