<script>
import vSelect from "vue-select";

const noop = () => {};
const DEFAULT_CONFIG = {
    minLength: 1,
    delay: 300,
    html: false,
    maxHeight: "300px", // height property
    onlySelectValid: true, // if true, element value must be selected from suggestion menu, otherwise set validity of 'onlySelectValid' to false.
    searchOnFocus: false,
};

export default Vue.component("fmmp-autocomplete", {
    props: {
        search: {
            type: Function,
            required: false,
        },
        config: {
            type: Object,
            default() {
                return DEFAULT_CONFIG;
            },
        },
        id: String,
        name: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        filterFunction: {
            type: Function,
            required: false,
        },
        items: {
            // TODO: define items prop type, should be array but need to adjust existing vue components
            required: false,
        },
        // Loading could be passed to show loader, while items are loaded outside of autocomplete
        loading: {
            type: Boolean,
            default: false,
        },
        value: {
            required: false,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        withImage: {
            type: Boolean,
            default: false,
        },
        showLabel: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        vSelect,
    },
    data() {
        return {
            options: [],
            searchTerm: "",
            lastSearch: "",
            skipNextChange: false,
        };
    },
    methods: {
        onChange(value) {
            if (this.skipNextChange) {
                this.skipNextChange = false;
                return;
            }

            if (value !== this.searchTerm) {
                this.searchTerm = value;
                this.$emit("input", value);
            }
        },
        onSearch(searchTerm = "") {
            this.lastSearch = searchTerm;
            this.options = [];

            if (searchTerm.length < this.mergedConfig.minLength) {
                return;
            }

            this.toggleLoading(true);
            this.search(searchTerm)
                .then((items) => {
                    this.options = items;
                })
                .finally(() => this.toggleLoading(false));
        },
        toggleLoading(showLoader) {
            this.$refs.select.toggleLoading(showLoader);
        },
        defaultFilterBy(option, label, search) {
            return (label || "").toLowerCase().indexOf(search.toLowerCase()) > -1;
        },
        noop,
        resetLastSearch() {
            this.lastSearch = "";
        },
        getImageOptions(options) {
            if (this.searchTerm) return (this.searchTerm = { ...options, isUrlValid: true });
            return (this.options = options.map((brand) => ({ ...brand, isUrlValid: true })));
        },
        onErrorImageLoad(updatedOption) {
            this.searchTerm = {
                ...this.searchTerm,
                isUrlValid: updatedOption.isUrlValid,
            };
            this.options = this.options.map((option) =>
                option.value === updatedOption.value ? updatedOption : option,
            );
        },
    },
    computed: {
        searchFn() {
            return this.search ? this.onSearch : noop;
        },
        searchOnFocusFn() {
            return this.mergedConfig.searchOnFocus ? this.onSearch : noop;
        },
        filterFn() {
            return this.filterFunction ? this.filterFunction : this.defaultFilterBy;
        },
        mergedConfig() {
            return {
                ...DEFAULT_CONFIG,
                ...this.config,
            };
        },
    },
    created() {
        if (this.items && this.search) {
            throw new Error("Only options or search function should provided");
        }
    },
    watch: {
        items: {
            immediate: true,
            handler(newVal, oldVal) {
                this.options = _.isEqual(newVal, oldVal) ? this.options : newVal;
                if (this.withImage) this.getImageOptions(this.options);
            },
        },
        value: {
            immediate: true,
            handler(newValue) {
                if (this.searchTerm !== newValue) {
                    // onChange is called when we set a new value from outside
                    this.skipNextChange = true;
                    this.searchTerm = newValue || "";
                    if (this.withImage) this.getImageOptions(this.searchTerm);
                }
            },
        },
    },
});
</script>
<template>
    <div class="autocomplete">
        <label id="autoselect-label" class="field-label sr-only"
            ><fmmp-i18n text="Search for" /> {{ placeholder }}</label
        >
        <v-select
            ref="select"
            :options="options"
            :value="searchTerm"
            aria-labelledby="autoselect-label"
            :id="id"
            :disabled="disabled || loading"
            :loading="loading"
            :maxHeight="mergedConfig.maxHeight"
            :taggable="!mergedConfig.onlySelectValid"
            :onChange="onChange"
            :placeholder="placeholder"
            :filterBy="filterFn"
            @search="searchFn"
            @search:focus="searchOnFocusFn"
            @search:blur="resetLastSearch"
        >
            <template slot="no-options">
                <div @mousedown.stop="noop">
                    <fmmp-i18n
                        v-if="lastSearch.length < mergedConfig.minLength"
                        text="Please type to search."
                    ></fmmp-i18n>
                    <fmmp-i18n v-else text="Sorry, no matching options."></fmmp-i18n>
                </div>
            </template>
            <template slot="option" slot-scope="option" v-if="mergedConfig.html">
                <span v-html="option" tabindex="0"></span>
            </template>

            // FOR IMAGES
            <template slot="selected-option" slot-scope="option">
                <fmmp-brandlogo
                    v-if="withImage"
                    :option="option"
                    @onErrorImageLoad="onErrorImageLoad"
                ></fmmp-brandlogo>
            </template>
            <template slot="option" slot-scope="option" v-if="withImage">
                <fmmp-brandlogo
                    :option="option"
                    @onErrorImageLoad="onErrorImageLoad"
                    :isDropdown="true"
                />
            </template>
        </v-select>
    </div>
</template>
