var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "driv-tab__wrapper" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isActive,
              expression: "isActive"
            }
          ],
          class: "driv-tab " + _vm.tabIsVisible
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      !_vm.tabsOnMobile
        ? _c(
            "driv-section",
            { attrs: { title: _vm.name } },
            [_vm._t("default")],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }