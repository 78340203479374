<template>
  <Popper
      :trigger="trigger"
      :options="options"
      :appendToBody="appendToBody"
      :visibleArrow="visibleArrow"
      :disabled="isDisabled"
      class="driv-popover"
      transition="fade"
      enterActiveClass="fade-enter-active"
      leaveActiveClass="fade-leave-active"
  >

    <div :class="['popper', popperClass]">
      <slot name="popper-content"></slot>
    </div>

    <div class="driv-popover-trigger" slot="reference">
      <slot name="popper-trigger"></slot>
    </div>
  </Popper>
</template>

<script>
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';

export default Vue.component("driv-popover", {
  props: {
    trigger: {
      type: String,
      default: 'hover',
    },
    placement: {
      type: String,
      default: 'top',
    },
    offset: {
      type: String,
      default: '',
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
    popperClass: {
      type: String,
      default: ''
    },
    visibleArrow: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Popper
  },
  computed: {
    options() {
      return {
        placement: this.placement,
        modifiers: {
          offset: {
            offset: this.offset
          }
        }
      }
    },
  },
})
</script>

