<template>
    <div>
        <driv-checkbox
                v-for="option in options"
                :key="option.id"
                :onChange="(e) => onChange(type, option.id, e.target.checked)"
                :checked="isChecked(value[type], option.id)"
                :disabled="isDisabled(value.enabled, option.id)"
        >
            <span class="panel-body_title">{{option.value}}</span>
        </driv-checkbox>
    </div>
</template>

<script>

    export default Vue.component("part-filters-checkbox-group", {
        props: {
            options: {
                type: Array,
                default: () => [],
            },
            value: {
                type: Object,
                default: () => {},
            },
            onChange: {
                type: Function,
            },
            type: {
                type: String,
                default: ''
            }
        },
        methods: {
            isDisabled(enabledValues, id) {
                if (!Object.keys(enabledValues).length) return false;

                if (!enabledValues[`${this.type}_id`]) return false;
                
                if (!enabledValues[`${this.type}_id`]?.length) return true;

                return !enabledValues[`${this.type}_id`].includes(id)
            },
            isChecked(group, id) {
                if (!group) return false;

                return group.includes(id);
            }
        }
    })
</script>
