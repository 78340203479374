var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["driv-section", { "show-content": _vm.isOpened }] },
    [
      _c(
        "button",
        {
          staticClass: "driv-section__title",
          on: { click: _vm.toggleContent }
        },
        [
          _vm._v(
            "\n        " + _vm._s(_vm.getTranslation(_vm.title)) + "\n    "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.title === "Part Types / Positions" && _vm.isWalker,
              expression: "title === 'Part Types / Positions' && isWalker"
            }
          ],
          staticClass: "driv-section__label"
        },
        [
          _vm._v(
            "\n        ( " +
              _vm._s(_vm.getTranslation("Select Part type to see positions")) +
              " )\n    "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "driv-section__content" },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }