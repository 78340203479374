<template>
  <div class="driv-dropdown-button">
    <a :class="buttonClasses">{{text}}</a>
    <ul class="driv-dropdown-button-options">
      <li v-for="option in options" :key="option.text" class="driv-dropdown-button-option">
        <a tabindex="0" @click="option.onClick">{{option.text}}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default Vue.component("driv-dropdown-button", {
  props: {
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    text: {
      type: String,
      default: "",
    },
    buttonClasses: {
      type: String,
      default: "",
    },
  },
});
</script>
