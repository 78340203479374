<template>
    <ag-grid-vue
        style="width: 100%"
        class="ag-theme-alpine"
        domLayout="autoHeight"
        :columnDefs="columnConfig"
        :rowData="tableData"
        :gridOptions="gridOptions"
    ></ag-grid-vue>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";

export default Vue.component("fmmp-table", {
    components: { AgGridVue },
    props: {
        // columns config - for all available properties see https://www.ag-grid.com/vue-data-grid/column-properties/
        columnConfig: {
            type: Array,
            default: () => [],
        },
        // every object in tableData is a row
        tableData: {
            type: Array,
            default: () => [],
        },
        gridOptions: {
            type: Object,
            default: () => ({}),
        },
    },
});
</script>
