var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.options, function(option) {
      return _c(
        "driv-checkbox",
        {
          key: option.id,
          attrs: {
            onChange: function(e) {
              return _vm.onChange(_vm.type, option.id, e.target.checked)
            },
            checked: _vm.isChecked(_vm.value[_vm.type], option.id),
            disabled: _vm.isDisabled(_vm.value.enabled, option.id)
          }
        },
        [
          _c("span", { staticClass: "panel-body_title" }, [
            _vm._v(_vm._s(option.value))
          ])
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }