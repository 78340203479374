<script>
import PortalVue from "portal-vue";
import { v4 as uuidv4 } from "uuid";

export default Vue.component("fmmp-viewer360", {
    components: {
        PortalVue,
    },
    props: {
        orderedImages: {
            type: Array,
            default: () => [],
        },
        spinReverse: {
            type: Boolean,
            require: true,
            default: false,
        },
        amount: {
            type: Number,
            require: true,
            default: 16,
        },
        loop: {
            type: Number,
            require: false,
            default: 1,
        },
        buttonClass: {
            type: String,
            require: false,
            default: "light",
        },
        disableZoom: {
            type: Boolean,
            require: false,
            default: false,
        },
        scrollImage: {
            type: Boolean,
            require: false,
            default: false,
        },
    },
    data() {
        return {
            minScale: 0.5,
            maxScale: 4,
            scale: 0.2,
            currentScale: 1.0,
            currentImage: null,
            dragging: false,
            canvas: null,
            ctx: null,
            dragStart: null,
            lastX: 0,
            lastY: 0,
            currentCanvasImage: null,
            isFullScreen: false,
            showFullscreenBtn: true,
            movementStart: 0,
            movement: false,
            speedFactor: 13,
            activeImage: 1,
            stopAtEdges: false,
            imagesLoaded: false,
            loadedImages: 0,
            centerX: 0,
            centerY: 0,
            panmode: false,
            isMobile: false,
            currentLoop: 0,
            loopTimeoutId: 0,
            images: [],
            imageData: [],
            initialPageContentZIndex: null,
            portalId: null,
            portalNode: null,
        };
    },
    watch: {
        amount() {
            this.fetchData();
        },
        panmode(value) {
            this.attachEvents();
        },
        isFullScreen(value) {
            try {
                const pageHeaderEl = document.querySelector(".page-header");
                const pageContentEl = document.querySelector(".page-content");

                if (this.isFullScreen) {
                    pageContentEl.style.zIndex = getComputedStyle(pageHeaderEl).zIndex;
                } else {
                    pageContentEl.style.zIndex = this.initialPageContentZIndex;
                }
            } catch (error) {
                console.error(
                    `Something went wrong while updating .page-content z-index (${error})`,
                );
                this.showFullscreenBtn = false;
                this.isFullScreen = false;
            }

            // We use $refs to access <canvas />.
            // But in rare cases $refs can't be accessable within the Portal content (https://portal-vue.linusb.org/guide/caveats.html#refs)
            // Using a double $nextTick resolve this issue (https://github.com/LinusBorg/portal-vue/issues/118#issuecomment-389023928)
            this.$nextTick(() => {
                this.$nextTick(() => {
                    // execute data initialisation asynchronously to fix 'this.canvas = undefined' issue in IE11 FullScreen mode;
                    setTimeout(() => {
                        this.initData();
                    }, 0);
                });
            });
        },
    },
    created() {
        this.portalNode = document.createElement("div");
        this.portalId = `portalId-${uuidv4()}`;
        this.portalNode.setAttribute("id", this.portalId);

        document.body.appendChild(this.portalNode);
    },
    mounted() {
        this.setInitialPageContentZIndex();
        this.fetchData();

        document.addEventListener("fullscreenchange", this.exitHandler);
        document.addEventListener("webkitfullscreenchange", this.exitHandler);
        document.addEventListener("mozfullscreenchange", this.exitHandler);
        document.addEventListener("MSFullscreenChange", this.exitHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeWindow);
        document.removeEventListener("fullscreenchange", this.exitHandler);
        document.removeEventListener("webkitfullscreenchange", this.exitHandler);
        document.removeEventListener("mozfullscreenchange", this.exitHandler);
        document.removeEventListener("MSFullscreenChange", this.exitHandler);

        document.body.removeChild(document.querySelector(`#${this.portalId}`));
    },
    methods: {
        initData() {
            this.checkMobile();
            this.loadInitialImage();

            this.canvas = this.$refs.imageContainer;
            this.ctx = this.canvas.getContext("2d");
            this.attachEvents();
            window.addEventListener("resize", this.resizeWindow);
            this.resizeWindow();
        },
        fetchData() {
            for (let i = 1; i <= this.amount; i++) {
                const filePath = this.orderedImages[i - 1];
                this.imageData.push(filePath.url);
            }
            this.preloadImages();
        },
        setInitialPageContentZIndex() {
            try {
                this.initialPageContentZIndex = getComputedStyle(
                    document.querySelector(".page-content"),
                ).zIndex;
            } catch (error) {
                console.error(
                    `Something went wrong while setting initialPageContentZIndex (${error.message})`,
                );
                this.showFullscreenBtn = false;
            }
        },
        lpad(str, padString, length) {
            str = str.toString();
            while (str.length < length) str = padString + str;
            return str;
        },
        preloadImages() {
            if (this.imageData.length) {
                try {
                    this.amount = this.imageData.length;
                    this.imageData.forEach((src) => {
                        this.addImage(src);
                    });
                } catch (error) {
                    console.error(`Something went wrong while loading images (${error.message})`);
                }
            } else {
                console.log("no images found while preloading viewer-360 images");
            }
        },
        addImage(resultSrc) {
            const image = new Image();
            image.src = resultSrc;
            image.onload = this.onImageLoad.bind(this);
            image.onerror = this.onImageLoad.bind(this);
            this.images.push(image);
        },
        onImageLoad(event) {
            const percentage = Math.round((this.loadedImages / this.amount) * 100);
            this.loadedImages += 1;
            this.updatePercentageInLoader(percentage);
            if (this.loadedImages === this.amount) {
                this.onAllImagesLoaded(event);
            }
        },
        updatePercentageInLoader(percentage) {
            this.$refs.viewPercentage.innerHTML = percentage + "%";
        },
        onAllImagesLoaded(e) {
            this.imagesLoaded = true;
            this.initData();
        },
        play() {
            this.loopTimeoutId = window.setInterval(() => this.loopImages(), 150);
        },
        stop() {
            if (this.activeImage == 1) {
                this.currentLoop = 0;
            }
            window.clearTimeout(this.loopTimeoutId);
        },
        loopImages() {
            if (this.activeImage == 1) {
                if (this.currentLoop == this.loop) {
                    this.stop();
                } else {
                    this.currentLoop++;

                    this.next();
                }
            } else {
                this.next();
            }
        },
        next() {
            this.spinReverse ? this.turnLeft() : this.turnRight();
        },
        prev() {
            this.spinReverse ? this.turnRight() : this.turnLeft();
        },
        turnLeft() {
            this.moveActiveIndexDown(1);
        },
        turnRight() {
            this.moveActiveIndexUp(1);
        },
        checkMobile() {
            this.isMobile = !!("ontouchstart" in window || navigator.msMaxTouchPoints);
        },
        loadInitialImage() {
            this.currentImage = this.imageData[0];
            this.setImage();
        },
        resizeWindow() {
            this.setImage();
        },
        attachEvents() {
            if (this.panmode) {
                this.bindPanModeEvents();
            } else {
                this.bind360ModeEvents();
            }
        },
        bindPanModeEvents() {
            this.$refs.viewport.removeEventListener("touchend", this.touchEnd);
            this.$refs.viewport.removeEventListener("touchstart", this.touchStart);
            this.$refs.viewport.removeEventListener("touchmove", this.touchMove);
            this.$refs.viewport.addEventListener("touchend", this.stopDragging);
            this.$refs.viewport.addEventListener("touchstart", this.startDragging);
            this.$refs.viewport.addEventListener("touchmove", this.doDragging);
            this.$refs.viewport.removeEventListener("mouseup", this.stopMoving);
            this.$refs.viewport.removeEventListener("mousedown", this.startMoving);
            this.$refs.viewport.removeEventListener("mousemove", this.doMoving);
            this.$refs.viewport.addEventListener("mouseup", this.stopDragging);
            this.$refs.viewport.addEventListener("mousedown", this.startDragging);
            this.$refs.viewport.addEventListener("mousemove", this.doDragging);
            this.$refs.viewport.addEventListener("wheel", this.onScroll);
        },
        bind360ModeEvents() {
            this.$refs.viewport.removeEventListener("touchend", this.stopDragging);
            this.$refs.viewport.removeEventListener("touchstart", this.startDragging);
            this.$refs.viewport.removeEventListener("touchmove", this.doDragging);
            this.$refs.viewport.addEventListener("touchend", this.touchEnd);
            this.$refs.viewport.addEventListener("touchstart", this.touchStart);
            this.$refs.viewport.addEventListener("touchmove", this.touchMove);
            this.$refs.viewport.removeEventListener("mouseup", this.stopDragging);
            this.$refs.viewport.removeEventListener("mousedown", this.startDragging);
            this.$refs.viewport.removeEventListener("mousemove", this.doDragging);

            this.$refs.viewport.addEventListener("mouseup", this.stopMoving);
            this.$refs.viewport.addEventListener("mousedown", this.startMoving);
            this.$refs.viewport.addEventListener("mousemove", this.doMoving);
            this.$refs.viewport.addEventListener("wheel", this.onScroll);
        },
        togglePanMode() {
            this.panmode = !this.panmode;
        },
        zoomIn(evt) {
            if (this.disableZoom) return;
            this.lastX = this.centerX;
            this.lastY = this.centerY;
            this.zoom(2);
        },
        zoomOut(evt) {
            if (this.disableZoom) return;

            this.lastX = this.centerX;
            this.lastY = this.centerY;
            this.zoom(-2);
        },
        resetPosition() {
            this.currentScale = 1;
            this.activeImage = 1;
            this.setImage(true);
        },
        setImage(cached = false) {
            if (!cached) {
                this.currentCanvasImage = new Image();
                this.currentCanvasImage.src = this.currentImage;
                this.currentCanvasImage.onload = this.onCanvasImagesLoad;
                this.currentCanvasImage.onerror = (err) => {
                    console.error(`cannot load this image (${onerror})`);
                };
            } else {
                this.currentCanvasImage = this.images[0];
                let viewportElement = this.$refs.viewport.getBoundingClientRect();
                this.canvas.width = this.isFullScreen
                    ? viewportElement.width
                    : this.currentCanvasImage.width;
                this.canvas.height = this.isFullScreen
                    ? viewportElement.height
                    : this.currentCanvasImage.height;
                this.trackTransforms(this.ctx);
                this.redraw();
            }
        },
        onCanvasImagesLoad() {
            let viewportElement = this.$refs.viewport.getBoundingClientRect();
            this.canvas.width = this.isFullScreen
                ? viewportElement.width
                : this.currentCanvasImage.width;
            this.canvas.height = this.isFullScreen
                ? viewportElement.height
                : this.currentCanvasImage.height;
            this.trackTransforms(this.ctx);
            this.redraw();
        },
        redraw() {
            try {
                let p1 = this.ctx.transformedPoint(0, 0);
                let p2 = this.ctx.transformedPoint(this.canvas.width, this.canvas.height);
                let hRatio = this.canvas.width / this.currentCanvasImage.width;
                let vRatio = this.canvas.height / this.currentCanvasImage.height;
                let ratio = Math.min(hRatio, vRatio);
                let centerShift_x = (this.canvas.width - this.currentCanvasImage.width * ratio) / 2;
                let centerShift_y =
                    (this.canvas.height - this.currentCanvasImage.height * ratio) / 2;
                this.ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
                this.centerX = (this.currentCanvasImage.width * ratio) / 2;
                this.centerY = (this.currentCanvasImage.height * ratio) / 2;

                this.ctx.drawImage(
                    this.currentCanvasImage,
                    0,
                    0,
                    this.currentCanvasImage.width,
                    this.currentCanvasImage.height,
                    centerShift_x,
                    centerShift_y,
                    this.currentCanvasImage.width * ratio,
                    this.currentCanvasImage.height * ratio,
                );
            } catch (e) {
                console.error("_____, ", e);
                this.trackTransforms(this.ctx);
            }
        },
        onMove(pageX) {
            if (pageX - this.movementStart >= this.speedFactor) {
                let itemsSkippedRight =
                    Math.floor((pageX - this.movementStart) / this.speedFactor) || 1;

                this.movementStart = pageX;
                if (this.spinReverse) {
                    this.moveActiveIndexDown(itemsSkippedRight);
                } else {
                    this.moveActiveIndexUp(itemsSkippedRight);
                }
                this.redraw();
            } else if (this.movementStart - pageX >= this.speedFactor) {
                let itemsSkippedLeft =
                    Math.floor((this.movementStart - pageX) / this.speedFactor) || 1;

                this.movementStart = pageX;
                if (this.spinReverse) {
                    this.moveActiveIndexUp(itemsSkippedLeft);
                } else {
                    this.moveActiveIndexDown(itemsSkippedLeft);
                }
                this.redraw();
            }
        },
        startMoving(evt) {
            this.movement = true;
            this.movementStart = evt.pageX;
            this.$refs.viewport.style.cursor = "grabbing";
        },
        doMoving(evt) {
            if (this.movement) {
                this.onMove(evt.clientX);
            }
        },
        onScroll(evt) {
            evt.preventDefault();

            // disable zoom on "wheel" event for IE (due to not smoothly scaling https://stackoverflow.com/a/26405990, IE users can use buttons "+","-" to zoom image instead)
            if (document.documentMode) return;

            if (this.disableZoom || this.scrollImage) {
                if (evt.deltaY < 0) {
                    this.moveActiveIndexDown(1);
                } else {
                    this.moveActiveIndexUp(1);
                }
                this.onMove(evt.scrollTop);
            } else {
                this.zoomImage(evt);
            }
        },
        moveActiveIndexUp(itemsSkipped) {
            if (this.stopAtEdges) {
                if (this.activeImage + itemsSkipped >= this.amount) {
                    this.activeImage = this.amount;
                } else {
                    this.activeImage += itemsSkipped;
                }
            } else {
                this.activeImage = (this.activeImage + itemsSkipped) % this.amount || this.amount;
            }

            this.update();
        },
        moveActiveIndexDown(itemsSkipped) {
            if (this.stopAtEdges) {
                if (this.activeImage - itemsSkipped <= 1) {
                    this.activeImage = 1;
                } else {
                    this.activeImage -= itemsSkipped;
                }
            } else {
                if (this.activeImage - itemsSkipped < 1) {
                    this.activeImage = this.amount + (this.activeImage - itemsSkipped);
                } else {
                    this.activeImage -= itemsSkipped;
                }
            }

            this.update();
        },
        update() {
            const image = this.images[this.activeImage - 1];
            this.currentCanvasImage = image;
            this.redraw();
        },
        stopMoving(evt) {
            this.movement = false;
            this.movementStart = 0;
            this.$refs.viewport.style.cursor = "grab";
        },
        touchStart(evt) {
            evt.stopPropagation();
            this.movementStart = evt.touches[0].clientX;
        },
        touchMove(evt) {
            evt.stopPropagation();
            this.onMove(evt.touches[0].clientX);
        },
        touchEnd(evt) {
            evt.stopPropagation();
            this.movementStart = 0;
        },
        startDragging(evt) {
            evt.stopPropagation();
            this.dragging = true;
            document.body.style.mozUserSelect =
                document.body.style.webkitUserSelect =
                document.body.style.userSelect =
                    "none";
            if (this.isMobile) {
                this.lastX =
                    evt.touches[0].offsetX || evt.touches[0].pageX - this.canvas.offsetLeft;
                this.lastY = evt.touches[0].offsetY || evt.touches[0].pageY - this.canvas.offsetTop;
            } else {
                this.lastX = evt.offsetX || evt.pageX - this.canvas.offsetLeft;
                this.lastY = evt.offsetY || evt.pageY - this.canvas.offsetTop;
            }

            this.dragStart = this.ctx.transformedPoint(this.lastX, this.lastY);
        },
        doDragging(evt) {
            evt.stopPropagation();

            if (this.isMobile) {
                this.lastX =
                    evt.touches[0].offsetX || evt.touches[0].pageX - this.canvas.offsetLeft;
                this.lastY = evt.touches[0].offsetY || evt.touches[0].pageY - this.canvas.offsetTop;
            } else {
                this.lastX = evt.offsetX || evt.pageX - this.canvas.offsetLeft;
                this.lastY = evt.offsetY || evt.pageY - this.canvas.offsetTop;
            }

            if (this.dragStart) {
                let pt = this.ctx.transformedPoint(this.lastX, this.lastY);
                this.ctx.translate(pt.x - this.dragStart.x, pt.y - this.dragStart.y);
                this.redraw();
            }
        },
        stopDragging(evt) {
            evt.stopPropagation();

            this.dragging = false;
            this.dragStart = null;
        },
        restrictScale() {
            let scale = this.currentScale;
            if (scale < this.minScale) {
                scale = this.minScale;
            } else if (scale > this.maxScale) {
                scale = this.maxScale;
            }
            return scale;
        },
        zoom(clicks) {
            let factor = Math.pow(1.01, clicks);
            if (factor > 1) {
                this.currentScale += factor;
            } else {
                if (this.currentScale - factor > 1) this.currentScale -= factor;
                else this.currentScale = 1;
            }

            if (this.currentScale > 1) {
                let pt = this.ctx.transformedPoint(this.lastX, this.lastY);
                this.ctx.translate(pt.x, pt.y);

                this.ctx.scale(factor, factor);
                this.ctx.translate(-pt.x, -pt.y);
                this.redraw();
            }
        },
        zoomImage(evt) {
            if (this.disableZoom) return;
            this.lastX = evt.offsetX || evt.pageX - this.canvas.offsetLeft;
            this.lastY = evt.offsetY || evt.pageY - this.canvas.offsetTop;

            var delta = evt.wheelDelta ? evt.wheelDelta / 40 : evt.deltaY ? -evt.deltaY : 0;

            if (delta) this.zoom(delta);
            return evt.preventDefault() && false;
        },
        trackTransforms(ctx) {
            return new Promise((resolve) => {
                var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                var xform = svg.createSVGMatrix();
                this.ctx.getTransform = function () {
                    return xform;
                };

                var savedTransforms = [];
                var save = ctx.save;
                this.ctx.save = () => {
                    savedTransforms.push(xform.translate(0, 0));
                    return save.call(this.ctx);
                };
                var restore = ctx.restore;
                this.ctx.restore = () => {
                    xform = savedTransforms.pop();
                    return restore.call(this.ctx);
                };
                var scale = this.ctx.scale;
                this.ctx.scale = (sx, sy) => {
                    xform = xform.scaleNonUniform(sx, sy);
                    return scale.call(this.ctx, sx, sy);
                };
                var rotate = this.ctx.rotate;
                this.ctx.rotate = (radians) => {
                    xform = xform.rotate((radians * 180) / Math.PI);
                    return rotate.call(this.ctx, radians);
                };
                var translate = this.ctx.translate;
                this.ctx.translate = (dx, dy) => {
                    xform = xform.translate(dx, dy);
                    return translate.call(this.ctx, dx, dy);
                };
                var transform = this.ctx.transform;
                this.ctx.transform = (a, b, c, d, e, f) => {
                    var m2 = svg.createSVGMatrix();
                    m2.a = a;
                    m2.b = b;
                    m2.c = c;
                    m2.d = d;
                    m2.e = e;
                    m2.f = f;
                    xform = xform.multiply(m2);
                    return transform.call(this.ctx, a, b, c, d, e, f);
                };
                var setTransform = this.ctx.setTransform;
                this.ctx.setTransform = (a, b, c, d, e, f) => {
                    xform.a = a;
                    xform.b = b;
                    xform.c = c;
                    xform.d = d;
                    xform.e = e;
                    xform.f = f;
                    return setTransform.call(this.ctx, a, b, c, d, e, f);
                };
                var pt = svg.createSVGPoint();
                this.ctx.transformedPoint = (x, y) => {
                    pt.x = x;
                    pt.y = y;
                    return pt.matrixTransform(xform.inverse());
                };
                resolve(this.ctx);
            });
        },
        toggleFullScreen() {
            this.isFullScreen = !this.isFullScreen;
        },
    },
});
</script>
<template>
    <portal :target-el="`#${this.portalId}`" :disabled="!isFullScreen">
        <!-- 360 Viewer Container -->
        <div
            :class="['v360-viewer-container', { 'v360-main v360-fullscreen': isFullScreen }]"
            ref="viewerContainer"
        >
            <!-- 360 Viewer Header -->
            <slot name="header"></slot>
            <!--/ 360 Viewer Header -->

            <!-- Percentage Loader -->
            <div class="v360-viewport" v-if="!imagesLoaded">
                <div class="v360-spinner-grow"></div>
                <p ref="viewPercentage" class="v360-percentage-text"></p>
            </div>
            <!--/ Percentage Loader -->

            <!-- 360 viewport -->
            <div class="v360-viewport" ref="viewport">
                <canvas class="v360-image-container" ref="imageContainer"></canvas>
            </div>
            <!--/ 360 viewport -->

            <!-- Fullscreen Button -->
            <abbr v-if="showFullscreenBtn" title="Fullscreen Toggle">
                <div class="v360-fullscreen-toggle text-center" @click="toggleFullScreen">
                    <div
                        :class="[
                            'v360-fullscreen-toggle-btn',
                            buttonClass == 'dark' ? 'text-light' : 'text-dark',
                        ]"
                    >
                        <i
                            :class="
                                !isFullScreen ? 'fas fa-expand text-lg' : 'fas fa-compress text-lg'
                            "
                        ></i>
                    </div>
                </div>
            </abbr>
            <!--/ Fullscreen Button -->

            <!-- Buttons Container -->
            <div id="v360-menu-btns" :class="buttonClass">
                <div class="v360-navigate-btns">
                    <div class="v360-menu-btns" @click="zoomIn" v-if="!disableZoom">
                        <i class="fa fa-search-plus"></i>
                    </div>
                    <div class="v360-menu-btns" @click="zoomOut" v-if="!disableZoom">
                        <i class="fa fa-search-minus"></i>
                    </div>
                    <div
                        @click="togglePanMode"
                        :class="['v360-menu-btns', { 'v360-btn-active': panmode }]"
                    >
                        <i class="fa fa-hand-paper-o" v-if="!panmode"></i>
                        <span v-else>360&deg;</span>
                    </div>
                    <div class="v360-menu-btns" @click="prev">
                        <i class="fa fa-chevron-left"></i>
                    </div>
                    <div class="v360-menu-btns" @click="next">
                        <i class="fa fa-chevron-right"></i>
                    </div>
                    <div class="v360-menu-btns" @click="resetPosition">
                        <i class="fa fa-sync"></i>
                    </div>
                </div>
            </div>
            <!--/ Buttons Container -->
        </div>
        <!--/ 360 Viewer Container -->
    </portal>
</template>
