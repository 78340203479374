var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "autocomplete" },
    [
      _c(
        "label",
        {
          staticClass: "field-label sr-only",
          attrs: { id: "autoselect-label" }
        },
        [
          _c("fmmp-i18n", { attrs: { text: "Search for" } }),
          _vm._v(" " + _vm._s(_vm.placeholder))
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-select",
        {
          ref: "select",
          attrs: {
            options: _vm.options,
            value: _vm.searchTerm,
            "aria-labelledby": "autoselect-label",
            id: _vm.id,
            disabled: _vm.disabled || _vm.loading,
            loading: _vm.loading,
            maxHeight: _vm.mergedConfig.maxHeight,
            taggable: !_vm.mergedConfig.onlySelectValid,
            onChange: _vm.onChange,
            placeholder: _vm.placeholder,
            filterBy: _vm.filterFn
          },
          on: {
            search: _vm.searchFn,
            "search:focus": _vm.searchOnFocusFn,
            "search:blur": _vm.resetLastSearch
          },
          scopedSlots: _vm._u(
            [
              {
                key: "option",
                fn: function(option) {
                  return _vm.withImage
                    ? [
                        _c("fmmp-brandlogo", {
                          attrs: { option: option, isDropdown: true },
                          on: { onErrorImageLoad: _vm.onErrorImageLoad }
                        })
                      ]
                    : undefined
                }
              },
              {
                key: "selected-option",
                fn: function(option) {
                  return [
                    _vm.withImage
                      ? _c("fmmp-brandlogo", {
                          attrs: { option: option },
                          on: { onErrorImageLoad: _vm.onErrorImageLoad }
                        })
                      : _vm._e()
                  ]
                }
              }
            ],
            null,
            true
          )
        },
        [
          _c("template", { slot: "no-options" }, [
            _c(
              "div",
              {
                on: {
                  mousedown: function($event) {
                    $event.stopPropagation()
                    return _vm.noop.apply(null, arguments)
                  }
                }
              },
              [
                _vm.lastSearch.length < _vm.mergedConfig.minLength
                  ? _c("fmmp-i18n", {
                      attrs: { text: "Please type to search." }
                    })
                  : _c("fmmp-i18n", {
                      attrs: { text: "Sorry, no matching options." }
                    })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm._v("\n\n        // FOR IMAGES\n        ")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }