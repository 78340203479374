<template>
    <ul class="driv-tabs-list__links">
        <li
            v-for="tab in tabs"
            :key="tab.value"
            :class="['tab-link', { 'tab-link_selected': currentTabValue === tab.value }]"
            @click="$emit('select', tab.value)"
        >
            {{ getTranslation(tab.label) }}
        </li>
    </ul>
</template>

<script>
export default Vue.component("driv-tabs-list", {
    props: {
        tabs: {
            type: Array,
            default: [],
        },
        currentTabValue: {
            type: String,
            default: "",
        },
    },
    methods: {
        getTranslation(text) {
            return Vue.i18n.get(text);
        },
    },
});
</script>
