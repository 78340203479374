var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "portal",
    {
      attrs: { "target-el": "#" + this.portalId, disabled: !_vm.isFullScreen }
    },
    [
      _c(
        "div",
        {
          ref: "viewerContainer",
          class: [
            "v360-viewer-container",
            { "v360-main v360-fullscreen": _vm.isFullScreen }
          ]
        },
        [
          _vm._t("header"),
          _vm._v(" "),
          !_vm.imagesLoaded
            ? _c("div", { staticClass: "v360-viewport" }, [
                _c("div", { staticClass: "v360-spinner-grow" }),
                _vm._v(" "),
                _c("p", {
                  ref: "viewPercentage",
                  staticClass: "v360-percentage-text"
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { ref: "viewport", staticClass: "v360-viewport" }, [
            _c("canvas", {
              ref: "imageContainer",
              staticClass: "v360-image-container"
            })
          ]),
          _vm._v(" "),
          _vm.showFullscreenBtn
            ? _c("abbr", { attrs: { title: "Fullscreen Toggle" } }, [
                _c(
                  "div",
                  {
                    staticClass: "v360-fullscreen-toggle text-center",
                    on: { click: _vm.toggleFullScreen }
                  },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          "v360-fullscreen-toggle-btn",
                          _vm.buttonClass == "dark" ? "text-light" : "text-dark"
                        ]
                      },
                      [
                        _c("i", {
                          class: !_vm.isFullScreen
                            ? "fas fa-expand text-lg"
                            : "fas fa-compress text-lg"
                        })
                      ]
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.buttonClass, attrs: { id: "v360-menu-btns" } },
            [
              _c("div", { staticClass: "v360-navigate-btns" }, [
                !_vm.disableZoom
                  ? _c(
                      "div",
                      {
                        staticClass: "v360-menu-btns",
                        on: { click: _vm.zoomIn }
                      },
                      [_c("i", { staticClass: "fa fa-search-plus" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.disableZoom
                  ? _c(
                      "div",
                      {
                        staticClass: "v360-menu-btns",
                        on: { click: _vm.zoomOut }
                      },
                      [_c("i", { staticClass: "fa fa-search-minus" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      "v360-menu-btns",
                      { "v360-btn-active": _vm.panmode }
                    ],
                    on: { click: _vm.togglePanMode }
                  },
                  [
                    !_vm.panmode
                      ? _c("i", { staticClass: "fa fa-hand-paper-o" })
                      : _c("span", [_vm._v("360°")])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "v360-menu-btns", on: { click: _vm.prev } },
                  [_c("i", { staticClass: "fa fa-chevron-left" })]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "v360-menu-btns", on: { click: _vm.next } },
                  [_c("i", { staticClass: "fa fa-chevron-right" })]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "v360-menu-btns",
                    on: { click: _vm.resetPosition }
                  },
                  [_c("i", { staticClass: "fa fa-sync" })]
                )
              ])
            ]
          )
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }