<script>
import PortalVue from "portal-vue";
import { v4 as uuidv4 } from "uuid";

export default Vue.component("fmmp-modal", {
    components: {
        PortalVue,
    },
    props: {
        rootClass: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            portalTargetId: null,
        };
    },
    computed: {
        partDetailPageMetadata() {
            return document.querySelector(".driv-part-detail-page-metadata");
        },
    },
    created() {
        const portalTargetNode = document.createElement("div");
        this.portalTargetId = `portalTargetId-${uuidv4()}`;
        portalTargetNode.setAttribute("id", this.portalTargetId);

        document.body.appendChild(portalTargetNode);
        document.body.style.overflowY = "hidden";
        if (this.partDetailPageMetadata) {
            this.partDetailPageMetadata.style.paddingRight = "36px";
        }
    },
    destroyed() {
        document.body.removeChild(document.querySelector(`#${this.portalTargetId}`));
        document.body.style.overflowY = "visible";
        if (this.partDetailPageMetadata) {
            this.partDetailPageMetadata.style.paddingRight = "20px";
        }
    },
});
</script>
<template>
    <portal :target-el="`#${this.portalTargetId}`" :target-class="rootClass">
        <div class="modal-content">
            <div v-if="$slots.icon" class="modal-title-icon">
                <slot name="icon"></slot>
            </div>
            <div v-if="$slots.title" class="modal-title">
                <slot name="title"></slot>
            </div>
            <button class="close" @click="$emit('close')">
                <i class="fa fa-close"></i>
            </button>
            <slot></slot>
        </div>
    </portal>
</template>
