<template>
    <div :class="containerClass">
        <div v-if="isDropdown || option.isUrlValid" :class="`image_container ${option.isUrlValid}`">
            <img
                v-if="option.isUrlValid"
                :src="getBrandImgUrl(option.label)"
                @error="onErrorImageLoad(option)"
            />
        </div>
        <span v-if="showLabel">{{ option.label }}</span>
    </div>
</template>

<script>
import { getBrandImgUrl } from "../../../../../../fmmp-base/components/content/driv-part-finder/common/partFinderCorporate.helpers";
export default Vue.component("fmmp-brandlogo", {
    props: {
        option: {
            type: Object,
            default: () => ({}),
        },
        containerClass: {
            type: String,
            default: "dropdown_item",
        },
        isDropdown: {
            type: Boolean,
            default: false,
        },
        isResult: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getBrandImgUrl,
        onErrorImageLoad(option) {
            this.$emit("onErrorImageLoad", { ...option, isUrlValid: false });
        },
    },
    computed: {
        showLabel() {
            return !this.isResult || (this.isResult && !this.option.isUrlValid);
        },
    },
});
</script>
