<template>
    <span>{{ translatedText }}</span>
</template>

<script>
export default Vue.component("fmmp-i18n", {
    props: {
        text: {
            type: String,
            required: true,
        },
        variables: Array,
        desc: String,
    },
    computed: {
        translatedText() {
            return Vue.i18n.get(this.text, this.variables, this.desc);
        },
    },
});
</script>
