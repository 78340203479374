var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Popper",
    {
      staticClass: "driv-popover",
      attrs: {
        trigger: _vm.trigger,
        options: _vm.options,
        appendToBody: _vm.appendToBody,
        visibleArrow: _vm.visibleArrow,
        disabled: _vm.isDisabled,
        transition: "fade",
        enterActiveClass: "fade-enter-active",
        leaveActiveClass: "fade-leave-active"
      }
    },
    [
      _c(
        "div",
        { class: ["popper", _vm.popperClass] },
        [_vm._t("popper-content")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "driv-popover-trigger",
          attrs: { slot: "reference" },
          slot: "reference"
        },
        [_vm._t("popper-trigger")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }