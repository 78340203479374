var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "async-autocomplete" },
    [
      _c("v-select", {
        attrs: {
          "aria-labelledby": "autoselect-label",
          label: "name",
          maxHeight: _vm.dropDownMenuHeight,
          filterable: false,
          options: _vm.options,
          placeholder: _vm.getTranslation(_vm.placeholder),
          onChange: _vm.handleSelect
        },
        on: { search: _vm.handleSearch, "search:blur": _vm.resetSearchValue },
        scopedSlots: _vm._u([
          {
            key: "no-options",
            fn: function(ref) {
              var search = ref.search
              return [
                _vm.searchValue.length < _vm.minCharsQty
                  ? _c("fmmp-i18n", { attrs: { text: _vm.noOptionsText } })
                  : _vm.isNoMatchingOptions()
                  ? _c("fmmp-i18n", {
                      attrs: { text: "Sorry, no matching options" }
                    })
                  : _vm._e()
              ]
            }
          },
          {
            key: "selected-option",
            fn: function(option) {
              return [
                _c("div", { staticClass: "selected d-center" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(option.name) +
                      "\n            "
                  )
                ])
              ]
            }
          }
        ]),
        model: {
          value: _vm.selectedPartNumber,
          callback: function($$v) {
            _vm.selectedPartNumber = $$v
          },
          expression: "selectedPartNumber"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }