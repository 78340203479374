<template>
    <div class="driv-tab__wrapper">
        <div :class="`driv-tab ${tabIsVisible}`" v-show="isActive">
            <slot></slot>
        </div>
        <driv-section v-if="!tabsOnMobile" :title="name">
            <slot></slot>
        </driv-section>
    </div>
</template>

<script>
export default Vue.component("driv-tab", {
    props: {
        name: {
            type: String,
            default: "Tab",
        },
        order: {
            type: Number,
            default: 0,
        },
        tabsOnMobile: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isActive: true,
        };
    },
    computed: {
        tabIsVisible() {
            return this.tabsOnMobile ? "visible" : "";
        },
    },
});
</script>
