<template>
    <div class="driv-tabs__container">
        <div class="driv-tabs__header_container">
            <div class="fa fa-chevron-left" v-if="isScroll" @click="scrollTabs('left')" />
            <ul class="driv-tabs__header">
                <li
                    v-for="(tab, index) in orderedTabs"
                    :key="tab.name"
                    @click="selectTab(index)"
                    v-on:keyup.enter="selectTab(index)"
                    :class="{ tab__selected: index === selectedIndex }"
                    tabindex="0"
                >
                    {{ getTranslation(tab.name) }}
                </li>
            </ul>
            <div class="fa fa-chevron-right" v-if="isScroll" @click="scrollTabs('right')" />
        </div>
        <slot></slot>
    </div>
</template>

<script>
import { scrollTabs } from "../../../../../../fmmp-base/components/content/driv-part-finder/common/partFinderCorporate.helpers";

export default Vue.component("driv-tabs", {
    props: {
        isTabToScroll: {
            type: Boolean,
            default: false,
        },
        activeTabIndex: {
            type: Number,
            default: undefined,
        },
    },
    data() {
        return {
            selectedIndex: 0, // the index of the selected tab,
            tabs: [], // all of the tabs
            isScrollable: false,
        };
    },
    created() {
        this.tabs = this.$children;
    },
    mounted() {
        this.isScrollable = this.tabs.length > 5 && window.screen.width < 1500;
    },
    computed: {
        orderedTabs() {
            if (this.tabs[0]?.order === undefined) return this.tabs;

            return this.tabs.sort((currTab, nextTab) => {
                return Number(currTab.order) - Number(nextTab.order);
            });
        },
        isScroll() {
            return this.isScrollable && this.isTabToScroll;
        },
    },
    methods: {
        selectTab(i) {
            this.selectedIndex = i;
            // loop over all the tabs
            this.tabs.forEach((tab, index) => {
                tab.isActive = index === i;
                if (this.isScroll) this.scrollOnSelect(i);
            });
        },
        scrollOnSelect(i) {
            if (i === 0) scrollTabs("left");
            if (i === this.tabs.length - 1) scrollTabs("right");
        },
        scrollTabs,
        getTranslation(text) {
            return Vue.i18n.get(text);
        },
    },
    watch: {
        tabs: {
            handler: function () {
                const tabIndex = this.activeTabIndex || 0;
                this.selectTab(tabIndex);
            },
            immediate: true,
        },
    },
});
</script>
