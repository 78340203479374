var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "driv-tabs__container" },
    [
      _c("div", { staticClass: "driv-tabs__header_container" }, [
        _vm.isScroll
          ? _c("div", {
              staticClass: "fa fa-chevron-left",
              on: {
                click: function($event) {
                  return _vm.scrollTabs("left")
                }
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "driv-tabs__header" },
          _vm._l(_vm.orderedTabs, function(tab, index) {
            return _c(
              "li",
              {
                key: tab.name,
                class: { tab__selected: index === _vm.selectedIndex },
                attrs: { tabindex: "0" },
                on: {
                  click: function($event) {
                    return _vm.selectTab(index)
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.selectTab(index)
                  }
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.getTranslation(tab.name)) +
                    "\n            "
                )
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.isScroll
          ? _c("div", {
              staticClass: "fa fa-chevron-right",
              on: {
                click: function($event) {
                  return _vm.scrollTabs("right")
                }
              }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }