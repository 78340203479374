<template>
    <div class="fmmp-select">
        <label v-if="label" :for="id" :class="{ required: required }">{{label}}</label>
        <select :id="id" :name="name" :value="value" @change="$emit('input', $event.target.value)" :disabled="disabled">
            <option value="" disabled>{{notSelectedLabel}}</option>
            <template v-for="option in options">
                <slot name="option" :option="option">
                    <option :value="option.id">{{option.name}}</option>
                </slot>
            </template>
        </select>
        <a class="select-box"><i class="fa fa-angle-down select-box-arrow"></i></a>
    </div>
</template>

<script>
    export default Vue.component("fmmp-select", {
        props: {
            options: {
                type: Array,
                required: true,
            },
            id: String,
            name: String,
            required: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            label: String,
            notSelectedLabel: String,
            value: {
                type: [String, Number],
                default: '',
            },
        },
    });
</script>
