var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.containerClass }, [
    _vm.isDropdown || _vm.option.isUrlValid
      ? _c("div", { class: "image_container " + _vm.option.isUrlValid }, [
          _vm.option.isUrlValid
            ? _c("img", {
                attrs: { src: _vm.getBrandImgUrl(_vm.option.label) },
                on: {
                  error: function($event) {
                    return _vm.onErrorImageLoad(_vm.option)
                  }
                }
              })
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showLabel ? _c("span", [_vm._v(_vm._s(_vm.option.label))]) : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }