<template>
    <div class="async-autocomplete">
        <v-select
            aria-labelledby="autoselect-label"
            label="name"
            v-model="selectedPartNumber"
            :maxHeight="dropDownMenuHeight"
            :filterable="false"
            :options="options"
            :placeholder="getTranslation(placeholder)"
            :onChange="handleSelect"
            @search="handleSearch"
            @search:blur="resetSearchValue"
        >
            <template #no-options="{ search }">
                <fmmp-i18n
                    v-if="searchValue.length < minCharsQty"
                    :text="noOptionsText"
                ></fmmp-i18n>
                <fmmp-i18n
                    v-else-if="isNoMatchingOptions()"
                    text="Sorry, no matching options"
                ></fmmp-i18n>
            </template>
            <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                    {{ option.name }}
                </div>
            </template>
        </v-select>
    </div>
</template>

<script>
import vSelect from "vue-select";
import _ from "lodash";

const MIN_CHARS_QTY = 4;
const DELAY_MS = 300;
const DROP_DOWN_MENU_HEIGHT = "300px";

export default Vue.component("fmmp-async-autocomplete", {
    props: {
        search: {
            type: Function,
            required: true,
        },
        onChange: {
            type: Function,
            required: true,
        },
        placeholder: {
            type: String,
            default: "",
        },
        noOptionsText: {
            type: String,
            default: "",
        },
        minCharsQty: {
            type: Number,
            default: MIN_CHARS_QTY,
        },
        delay: {
            type: Number,
            default: DELAY_MS,
        },
        dropDownMenuHeight: {
            type: String,
            default: DROP_DOWN_MENU_HEIGHT,
        },
        options: {
            type: Array,
            default: [],
        },
        sku: {
            type: String,
            default: '',
        },
    },
    components: {
        vSelect,
    },

    data() {
        return {
            searchValue: "",
            selectedPartNumber: this.sku,
        };
    },
    methods: {
        getTranslation(text) {
            return Vue.i18n.get(text);
        },

        handleSearch(value, loading) {
            //trim spaces at the beginning of the searched value
            this.searchValue = value.replace(/^\s+/, "");

            if (this.searchValue.length < this.minCharsQty) {
                return;
            }
            loading(true);
            this.debouncedSearch(loading);
        },

        resetSearchValue() {
            this.searchValue = "";
        },

        handleSelect(value) {
            this.onChange(value);
        },

        isNoMatchingOptions() {
            return this.searchValue && this.options.length === 0;
        },
    },
    created() {
        this.debouncedSearch = _.debounce((loading) => {
            this.search(this.searchValue, loading);
        }, this.delay);
    },
    watch: {
        sku(newPartNumber) {
            this.selectedPartNumber = newPartNumber;
        }
    },
    beforeDestroy() {
        this.onSearch.cancel();
    },
});
</script>
