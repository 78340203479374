<template>
    <div class="driv-checkbox">
        <label :class="['checkbox-label', { disabled }]">
            <input
                    type="checkbox"
                    :checked="checked"
                    @change="onChange"
                    class="checkbox-label_input"
                    :disabled="disabled"
            >
            <slot></slot>
        </label>
    </div>
</template>

<script>
    export default Vue.component("driv-checkbox", {
        props: {
            checked: {
                type: Boolean,
                default: false,
            },
            onChange: {
                type: Function,
            },
            disabled: {
                type: Boolean,
                default: false,
            }
        },
    })
</script>

