var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "portal",
    {
      attrs: {
        "target-el": "#" + this.portalTargetId,
        "target-class": _vm.rootClass
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal-content" },
        [
          _vm.$slots.icon
            ? _c(
                "div",
                { staticClass: "modal-title-icon" },
                [_vm._t("icon")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$slots.title
            ? _c("div", { staticClass: "modal-title" }, [_vm._t("title")], 2)
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "close",
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("i", { staticClass: "fa fa-close" })]
          ),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }