var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fmmp-select" }, [
    _vm.label
      ? _c(
          "label",
          { class: { required: _vm.required }, attrs: { for: _vm.id } },
          [_vm._v(_vm._s(_vm.label))]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "select",
      {
        attrs: { id: _vm.id, name: _vm.name, disabled: _vm.disabled },
        domProps: { value: _vm.value },
        on: {
          change: function($event) {
            return _vm.$emit("input", $event.target.value)
          }
        }
      },
      [
        _c("option", { attrs: { value: "", disabled: "" } }, [
          _vm._v(_vm._s(_vm.notSelectedLabel))
        ]),
        _vm._v(" "),
        _vm._l(_vm.options, function(option) {
          return [
            _vm._t(
              "option",
              function() {
                return [
                  _c("option", { domProps: { value: option.id } }, [
                    _vm._v(_vm._s(option.name))
                  ])
                ]
              },
              { option: option }
            )
          ]
        })
      ],
      2
    ),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "select-box" }, [
      _c("i", { staticClass: "fa fa-angle-down select-box-arrow" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }